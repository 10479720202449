import { default as dashboardhKii8fXWAxMeta } from "/usr/src/app/pages/dashboard.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as qr6oE3rSSS1wMeta } from "/usr/src/app/pages/qr.vue?macro=true";
import { default as indexTxpEjcu9f0Meta } from "/usr/src/app/pages/signUp/index.vue?macro=true";
import { default as companybZZeKwWWHDMeta } from "/usr/src/app/pages/signUp/success/company.vue?macro=true";
import { default as solo22avLaaE69Meta } from "/usr/src/app/pages/signUp/success/solo.vue?macro=true";
import { default as SSO7uTOqwUjvxMeta } from "/usr/src/app/pages/SSO.vue?macro=true";
import { default as docsowDGxd1LLjMeta } from "/usr/src/app/pages/static/docs.vue?macro=true";
import { default as privacyPolicy8xZOQKXAfuMeta } from "/usr/src/app/pages/static/privacyPolicy.vue?macro=true";
import { default as termsOfServicebNwoJi27kwMeta } from "/usr/src/app/pages/static/termsOfService.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/usr/src/app/pages/dashboard.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "qr",
    path: "/qr",
    component: () => import("/usr/src/app/pages/qr.vue")
  },
  {
    name: "signUp",
    path: "/signUp",
    component: () => import("/usr/src/app/pages/signUp/index.vue")
  },
  {
    name: "signUp-success-company",
    path: "/signUp/success/company",
    component: () => import("/usr/src/app/pages/signUp/success/company.vue")
  },
  {
    name: "signUp-success-solo",
    path: "/signUp/success/solo",
    component: () => import("/usr/src/app/pages/signUp/success/solo.vue")
  },
  {
    name: "SSO",
    path: "/SSO",
    component: () => import("/usr/src/app/pages/SSO.vue")
  },
  {
    name: "static-docs",
    path: "/static/docs",
    component: () => import("/usr/src/app/pages/static/docs.vue")
  },
  {
    name: "static-privacyPolicy",
    path: "/static/privacyPolicy",
    component: () => import("/usr/src/app/pages/static/privacyPolicy.vue")
  },
  {
    name: "static-termsOfService",
    path: "/static/termsOfService",
    component: () => import("/usr/src/app/pages/static/termsOfService.vue")
  }
]